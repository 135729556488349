import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "navbar is-fixed-bottom" }
const _hoisted_2 = { class: "navbar-brand" }
const _hoisted_3 = { class: "navbar-start" }
const _hoisted_4 = { class: "navbar-end" }
const _hoisted_5 = {
  key: 0,
  class: "navbar-item"
}
const _hoisted_6 = { class: "navbar-item" }
const _hoisted_7 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-item",
        to: "/"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("h1", { class: "title" }, "LIFF", -1)
        ])),
        _: 1
      }),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.makeBurger && _ctx.makeBurger(...args))),
        type: "button",
        class: _normalizeClass(["button navbar-burger", { 'is-active': _ctx.activator }]),
        "aria-label": "menu",
        "aria-expanded": "false",
        "data-target": "navbarBasicExample"
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
        _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
        _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
      ]), 2)
    ]),
    _createElementVNode("div", {
      id: "navbarBasicExample",
      class: _normalizeClass(["navbar-menu", { 'is-active': _ctx.activator }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "navbar-item",
          to: "/"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Home ")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navbar-item",
          to: "/about"
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" About ")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isLogin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Welcome, " + _toDisplayString(_ctx.userData.displayName) + "! ", 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (!_ctx.isLogin)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "button is-primary",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
                }, "Login"))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "button is-danger",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                }, "Logout"))
          ])
        ])
      ])
    ], 2)
  ]))
}