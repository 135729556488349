import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/user.png'


const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-content" }
const _hoisted_5 = {
  key: 0,
  class: "content"
}
const _hoisted_6 = {
  key: 1,
  class: "content"
}
const _hoisted_7 = { class: "image is-128x128" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "is-rounded",
  src: _imports_0
}
const _hoisted_10 = { class: "buttons" }
const _hoisted_11 = {
  key: 0,
  class: "content"
}
const _hoisted_12 = { class: "columns is-multiline" }
const _hoisted_13 = { class: "box" }
const _hoisted_14 = { class: "buttons" }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_input = _resolveComponent("o-input")!
  const _component_o_field = _resolveComponent("o-field")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Please login first. To use our apps."))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("figure", _hoisted_7, [
                  (_ctx.userData.pictureUrl)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "is-rounded",
                        src: _ctx.userData.pictureUrl
                      }, null, 8, _hoisted_8))
                    : (_openBlock(), _createElementBlock("img", _hoisted_9))
                ]),
                _createVNode(_component_o_field, { label: "Message" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_o_input, {
                      modelValue: _ctx.message,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createElementVNode("button", {
                  class: "button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addNote && _ctx.addNote(...args)))
                }, "Submit")
              ])),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              class: "button is-link",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openAnother && _ctx.openAnother(...args)))
            }, "Open Author Personal Web"),
            (_ctx.isLogin)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "button is-success",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.getAccessToken && _ctx.getAccessToken(...args)))
                }, "Get Access Token"))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.isLogin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "title" }, "My Notes", -1)),
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notes, (note, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "column",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("p", null, _toDisplayString(note), 1),
                    _createElementVNode("div", _hoisted_14, [
                      (_ctx.isClient)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "button is-link",
                            onClick: ($event: any) => (_ctx.submit(index))
                          }, "Send To Chat", 8, _hoisted_15))
                        : _createCommentVNode("", true),
                      (_ctx.isLogin)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "button is-danger",
                            onClick: ($event: any) => (_ctx.deleteNote(index))
                          }, "Delete", 8, _hoisted_16))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}